import React from 'react';
import { withRouter } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import AppContext, { AppContextHandler } from '../../shared/store/AppContext';
import { Link } from 'react-router-dom';

import './Navbar.scss';
import Conditional from '../ui/Conditional/Conditional';
import { StringUtil } from '../../utils';
import { obter_notificacoes_gerais } from '../../services/notificacoes.functions';

interface NavbarState {
    totalQuestionariosParaResponder: number;
    totalNotificacoes: number;
}

class Navbar extends React.Component<any, NavbarState> {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            totalQuestionariosParaResponder: 0,
            totalNotificacoes: 0
        }
    }

    componentDidMount() {
        if (this.context) {
            const context = this.context as AppContextHandler;
            context.notificacoesGerais?.subscribe(x => {
                setTimeout(this.atualizarTotalNotificacoes.bind(this), 3500)
            });
        }

        //this.atualizarTotalNotificacoes();
    }

    atualizarTotalNotificacoes() {
        obter_notificacoes_gerais()
            .then(x => x.data)
            .then(x => {
                this.setState({
                    ...this.state,
                    totalQuestionariosParaResponder: x.total_questionarios_disponiveis,
                    totalNotificacoes: (x.total_questionarios_disponiveis)
                })
            })
    }

    render() {
        const nomeUsuario = this.context?.user?.name;
        const logOut = () => {
            (this.context as AppContextHandler).setUser(null);

            const authService = new AuthService();
            authService.logout();

            this.props.history.push({
                pathname: '/login'
            });
        };

        //<!-- Navbar -->
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/" className="nav-link">
                            <i className="fas fa-home nav-icon"></i>
                        </Link>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block" style={{ 'display': 'none' }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a style={{ 'display': 'none' }} href="#" className="nav-link">Contact</a>
                    </li>
                </ul>

                {/* SEARCH FORM */}
                <form className="form-inline ml-3" style={{ 'display': 'none' }}>
                    <div className="input-group input-group-sm">
                        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-navbar" type="submit">
                                <i className="fas fa-search" />
                            </button>
                        </div>
                    </div>
                </form>

                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">

                    {/* Messages Dropdown Menu */}
                    <li style={{ 'display': 'none' }} className="nav-item dropdown">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-comments" />
                            <span className="badge badge-danger navbar-badge">3</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    {/* <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" /> */}
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                                            <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    {/* <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" /> */}
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            John Pierce
                                            <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">I got your message bro</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item">
                                {/* Message Start */}
                                <div className="media">
                                    {/* <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" /> */}
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Nora Silvester
                                            <span className="float-right text-sm text-warning">
                                                <i className="fas fa-star" />
                                            </span>
                                        </h3>
                                        <p className="text-sm">The subject goes here</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                {/* Message End */}
                            </a>
                            <div className="dropdown-divider" />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                        </div>
                    </li>

                    {/* Notifications Dropdown Menu */}
                    <li className="nav-item dropdown">
                        <Link to="/responder-questionario/geral" className="nav-link">
                            <i className="far fa-bell" />
                            <span className="badge badge-warning navbar-badge">{this.state.totalNotificacoes}</span>
                        </Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="nav-link" data-toggle="dropdown" href="#"  style={{ 'display': 'none' }}>
                            <i className="far fa-bell" />
                            <span className="badge badge-warning navbar-badge">{this.state.totalNotificacoes}</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right"  style={{ 'display': 'none' }}>

                            {/* <Link to="/responder-questionario/geral" className="dropdown-item">
                                <i className="fas fa-edit mr-2" /> {this.state.totalQuestionariosParaResponder} Questionariário p/ respon.
                            </Link> */}

                            <span className="dropdown-item dropdown-header" style={{ 'display': 'none' }}>15 Notifications</span>
                            <div className="dropdown-divider" style={{ 'display': 'none' }} />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item" style={{ 'display': 'none' }}>
                                <i className="fas fa-envelope mr-2" /> 4 new messages
                                <span className="float-right text-muted text-sm">3 mins</span>
                            </a>
                            <div className="dropdown-divider" style={{ 'display': 'none' }} />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item" style={{ 'display': 'none' }}>
                                <i className="fas fa-users mr-2" /> 8 friend requests
                                <span className="float-right text-muted text-sm">12 hours</span>
                            </a>
                            <div className="dropdown-divider" style={{ 'display': 'none' }} />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item" style={{ 'display': 'none' }}>
                                <i className="fas fa-file mr-2" /> 3 new reports
                                <span className="float-right text-muted text-sm">2 days</span>
                            </a>
                            <div className="dropdown-divider" style={{ 'display': 'none' }} />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="dropdown-item dropdown-footer" style={{ 'display': 'none' }}>See All Notifications</a>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <Conditional condition={!StringUtil.isNullOrEmpty(nomeUsuario)}>
                                <span className="mr-2">{nomeUsuario}</span>
                            </Conditional>
                            <i className="fas fa-user-circle nav-icon"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">Ação do usuário</span>
                            <div className="dropdown-divider" />
                            <Link to="/alterar-senha" className="nav-link">
                                <i className="fas fa-key mr-2 nav-icon"></i> Alterar Senha
                            </Link>
                            <div className="dropdown-divider" />
                            <button className="btn btn-link nav-link" title="Sair" onClick={logOut.bind(this)} >
                                <i className="fas fa-door-open mr-2" /> Sair
                            </button>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default withRouter(Navbar);