import React from 'react';

function LoadingGeneral() {
    return (
        <div className="wrapper">

            <div className="preloader flex-column justify-content-center align-items-center">
                <div>
                    <div className="d-flex align-items-center">
                        <i className="fa fa-3x fa-circle-notch fa-spin" />
                        <span style={{ fontSize: '30px', paddingLeft: '10px' }}>Carregando...</span>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default LoadingGeneral;