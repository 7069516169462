type andf = any | undefined;

export interface AppSubject<T = andf> {
    get value() : T;
    subscribe(sub: (value: T) => void);
    next(value: T);
}

export class MySubject<T = andf> implements AppSubject<T> {

    private _subscriptions: ((value: T) => void)[] = [];
    private _value: T;

    get value() {
        return this._value;
    }

    constructor(value: T | undefined = undefined) {
        this._value = value as T;
    }

    subscribe(
        sub: (value: T) => void
    ) {
        this._subscriptions.push(sub);
        sub(this.value);
    }

    next(value: T) {

        this._value = value;
        this._subscriptions.forEach(x => x(value));
    }
}