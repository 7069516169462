export class ObjectUtil {
    static sanatizeEmptyFields<T = any>(obj : T, df = null) {
        if (obj === null || obj === undefined)
            return obj;
        
        Object.keys(obj)
            .forEach(key => {
                let sub = obj[key];
                if (typeof sub === 'string' && (sub === '' || sub.length <= 0)) {
                    obj[key] = df
                } else if (typeof sub == 'object' && !Array.isArray(sub)) {
                    obj[key] = ObjectUtil.sanatizeEmptyFields(sub, df);
                }
            });

        return obj;
    }
}