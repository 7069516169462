import React from 'react'

function Loading() {    
    return <div id="div_modal_loading" style={{ display: 'none' }}>
        <div style={{ width: '200px', zIndex: 10051, position: 'absolute', top: '50%', left: '50%', margin: '-12px 0 0 -100px' }}>
            <div className="progress progress-striped active">
                <div className="progress-bar progress-bar-layout" style={{ width: '100%' }}>Carregando...</div>
            </div>
        </div>
    </div>
};

export default Loading;