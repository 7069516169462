/*eslint eqeqeq: [off]*/
export class MaskUtil {
    /**
     * @param {string} mask mascara
     * @param {string} str string
     * @param {RegExp} replace regex para substuir
     * @param {string} char Caracter de base para substituir
     * @return {string}
     */
    static putMask(mask: string, str: string, replace: any, char: string = '#'): string {

        if (!str)
            str = '';

        if (str.length <= 0)
            return str;

        if (typeof replace !== 'undefined')
            str = str.replace(replace, '');

        let result = "", idx = 0, len = mask.length;

        for (let i = 0; i < len; i++) {
            let charRes = mask.charAt(i);
            //@ts-ignore
            if (charRes == char) {
                charRes = str.charAt(idx);
                idx++;
            }

            result += charRes;
        }

        return result;
    }

    static cnpj(doc) {
        //@ts-ignore
        return MaskUtil.putMask('##.###.###/####-##', doc, /[.\-/]/gi);
    }

    static cpf(doc) {
        //@ts-ignore
        return MaskUtil.putMask('###.###.###-##', doc, /[.\-/]/);
    }

    static cnpj_cpf(doc) {
        if (!doc)
            doc = '';

        if (doc.length >= 14) {
            return MaskUtil.cnpj(doc);
        }

        return MaskUtil.cpf(doc);
    }

    static cep(doc) {
        //@ts-ignore
        return MaskUtil.putMask('##.###-###', doc, /[.\-/]/gi);
    }

    static telefone(tel) {
        //@ts-ignore
        return MaskUtil.putMask('(##) ####-#####', tel, /[\D]/);
    }
}