import React, { Component } from 'react'
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

import './MainSidebar.scss';



class MainSidebar extends Component {    
    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4 autoprime__sidebar-dark">
                { /* <!-- Brand Logo --> */}
                <Link to="/" className="brand-link">
                    {/* <img src={`${process.env.PUBLIC_URL}/img/logo_test.png`} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                    <img src={`${process.env.PUBLIC_URL}/img/logos/logo-branco-sem-nome.png`} alt="Autoprime Logo" className="brand-image" style={{ minHeight: '40px', minWidth: '40px'}}/>
                    <img src={`${process.env.PUBLIC_URL}/img/logos/logo-branco-so-nome.png`} alt="Autoprime Logo"  className="brand-text font-weight-light"  style={{ maxWidth: '90px'}}/>
                    {/* <span className="brand-text font-weight-light">AUTOPRIME</span> */}
                </Link>
                <Sidebar />
            </aside>
        );
    }

}

export default MainSidebar;