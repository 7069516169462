export const permissoes = {
    ADMINISTRADOR: 'USER_ADMINISTRADOR',

    DASHBOARD_VISUALIZAR: 'DASHBOARD_VISUALIZAR',

    //CARDS
    CARD_VISUALIZAR: 'CARD_VISUALIZAR',
    CARD_LISTAR: 'CARD_LISTAR',
    CARD_CRIAR: 'CARD_CRIAR',
    CARD_EDITAR: 'CARD_EDITAR',
    CARD_EXCLUIR: 'CARD_EXCLUIR',
    
    //EMPRESA
    EMPRESA_VISUALIZAR: 'EMPRESA_VISUALIZAR',
    EMPRESA_LISTAR: 'EMPRESA_LISTAR',
    EMPRESA_CRIAR: 'EMPRESA_CRIAR',
    EMPRESA_EDITAR: 'EMPRESA_EDITAR',
    EMPRESA_EXCLUIR: 'EMPRESA_EXCLUIR',

    //Usuário
    USUARIO_VISUALIZAR: 'USUARIO_VISUALIZAR',
    USUARIO_LISTAR: 'USUARIO_LISTAR',
    USUARIO_CRIAR: 'USUARIO_CRIAR',
    USUARIO_EDITAR: 'USUARIO_EDITAR',
    USUARIO_EXCLUIR: 'USUARIO_EXCLUIR',

    //Grupo
    GRUPO_LISTAR: 'GRUPO_LISTAR',
    GRUPO_VISUALIZAR: 'GRUPO_VISUALIZAR',
    GRUPO_CRIAR: 'GRUPO_CRIAR',
    GRUPO_EDITAR: 'GRUPO_EDITAR',
    GRUPO_EXCLUIR: 'GRUPO_EXCLUIR',

    //Formularios
    QUESTIONARIO_LISTAR: 'QUESTIONARIO_LISTAR',
    QUESTIONARIO_VISUALIZAR: 'QUESTIONARIO_VISUALIZAR',
    QUESTIONARIO_CRIAR: 'QUESTIONARIO_CRIAR',
    QUESTIONARIO_EDITAR: 'QUESTIONARIO_EDITAR',
    QUESTIONARIO_EXCLUIR: 'QUESTIONARIO_EXCLUIR',
    QUESTIONARIO_DEFINIR_META_PERGUNTA: 'QUESTIONARIO_DEFINIR_META_PERGUNTA',
    QUESTIONARIO_PAUSAR_QUESTIONARIO: 'QUESTIONARIO_PAUSAR_QUESTIONARIO',
    QUESTIONARIO_EXCLUIR_REGISTRO_FILA: 'QUESTIONARIO_EXCLUIR_REGISTRO_FILA',


    //GRUPO ECONOMICO
    GRUPO_ECONOMICO_VISUALIZAR: 'GRUPO_ECONOMICO_VISUALIZAR',
    GRUPO_ECONOMICO_LISTAR: 'GRUPO_ECONOMICO_LISTAR',
    GRUPO_ECONOMICO_CRIAR: 'GRUPO_ECONOMICO_CRIAR',
    GRUPO_ECONOMICO_EDITAR: 'GRUPO_ECONOMICO_EDITAR',
    GRUPO_ECONOMICO_EXCLUIR: 'GRUPO_ECONOMICO_EXCLUIR',

    //AREA ATUACAO
    AREA_ATUACAO_VISUALIZAR: 'AREA_ATUACAO_VISUALIZAR',
    AREA_ATUACAO_LISTAR: 'AREA_ATUACAO_LISTAR',
    AREA_ATUACAO_CRIAR: 'AREA_ATUACAO_CRIAR',
    AREA_ATUACAO_EDITAR: 'AREA_ATUACAO_EDITAR',
    AREA_ATUACAO_EXCLUIR: 'AREA_ATUACAO_EXCLUIR',

    //SETOR EMPRESA
    SETOR_EMPRESA_VISUALIZAR: 'SETOR_EMPRESA_VISUALIZAR',
    SETOR_EMPRESA_LISTAR: 'SETOR_EMPRESA_LISTAR',
    SETOR_EMPRESA_CRIAR: 'SETOR_EMPRESA_CRIAR',
    SETOR_EMPRESA_EDITAR: 'SETOR_EMPRESA_EDITAR',
    SETOR_EMPRESA_EXCLUIR: 'SETOR_EMPRESA_EXCLUIR',

    //GRAFICO QUESTIONARIO
    GRAFICO_QUESTIONARIO_VISUALIZAR: 'GRAFICO_QUESTIONARIO_VISUALIZAR',
    GRAFICO_QUESTIONARIO_LISTAR: 'GRAFICO_QUESTIONARIO_LISTAR',
    GRAFICO_QUESTIONARIO_CRIAR: 'GRAFICO_QUESTIONARIO_CRIAR',
    GRAFICO_QUESTIONARIO_EDITAR: 'GRAFICO_QUESTIONARIO_EDITAR',
    GRAFICO_QUESTIONARIO_EXCLUIR: 'GRAFICO_QUESTIONARIO_EXCLUIR',


    PERMISSOES_LISTAR: 'PERMISSOES_LISTAR',
}