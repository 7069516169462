import React from 'react'

interface PageProps {
    title?: string;
    children: any
}

/* <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Blank Page</li>
                        </ol>
                    </div> */

const PageHeader = (props: PageProps) => {
    if (props.title === undefined) {
        return <></>;
    }
    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        { props.title ? ( <h1>{props.title}</h1> ) : (<></>)}
                    </div>
                </div>
            </div>{/* /.container-fluid */}
        </section>
    );
}

const Page = (props: PageProps) => {
    return (
        <>
            <PageHeader {...props} />
            <section className="content">
                {props.children}
            </section>
        </>
    );
}

export default Page;