import { AxiosResponse } from "axios";
import { AlterarSenhaRequest } from "../models/auth";
import { ApiDefaultResponse, DefaultResponse } from "../models/sistema";
import { AppUser } from "../models/usuario";
import config from "../shared/constants";
import { BaseService, USER_KEY_STORAGE } from "./BaseService";
export class AuthService extends BaseService {

    async login(email: string, password: string): Promise<AxiosResponse<AppUser>> {
        return await this.axios({ headers: { 'Content-Type': 'application/json' } })
            .post<AppUser>(config.AuthApi.Login, { email: email, password: password })
    }

    async logout() {
        window.sessionStorage.removeItem('__app-permissoes');
        this.storage.remove(USER_KEY_STORAGE);
        
    }

    async verificarUsuarioLogado() {

        try {
            const isAlive = await this.axios().get<string>(config.AuthApi.IsTokenAlive);
            console.info(isAlive?.data);

        } catch (err) {
            if (err && err.response) {
                const response = err.response;

                if (response.status === 401) {
                    (new AuthService()).logout();
                    const url = `${config.BaseAppUrl}/`;

                    window.location.href = url;
                }
            }
        }

    }

    async alterarSenha(request: AlterarSenhaRequest): Promise<ApiDefaultResponse> {
        return await this.axios()
            .post<DefaultResponse>(config.AuthApi.AlterarSenha, request)
    }

    async esqueciSenha(request: { email: string }): Promise<ApiDefaultResponse> {
        return await this.axios({ headers: { 'Content-Type': 'application/json' } })
            .post<DefaultResponse>(config.AuthApi.EsqueciSenha, request);
    }

    async carregarPermissoesUsuario(): Promise<{ [x: string]: string }> {

        const user = this.getUser();
        const userId = (user) ? user.id : 0;

        let permissoes = this.getPermissoes();

        if (permissoes && permissoes.user_id !== userId) {
            window.sessionStorage.removeItem('__app-permissoes');
            permissoes = undefined;
        }

        if (!permissoes) {
            try {
                
                const response = await this.axios().get(config.AuthApi.obterPermissoesDoUsuario);
                const permissoes = response.data;

                if (permissoes) {
                    permissoes['user_id'] = userId;
                    this.setPermissoes(permissoes);
                }
            } catch (err) {
                //ignore
                console.error(err);
            }
        }

        permissoes = this.getPermissoes();

        if (!permissoes) {
            return {} as { [x: string]: string };
        }

        return permissoes as any;
    }

    async verificarPermissoesUsuario(validarPermissoes : string[]) : Promise<{ [x:string] : boolean }> {

        const permissoes = await this.carregarPermissoesUsuario();
        const isAdmin = permissoes['ADMINISTRADOR'] !== undefined;
        const QUESTIONARIO_EDITAR = 'QUESTIONARIO_EDITAR'
        const result: any = {};
        
        validarPermissoes.forEach(permissao => {
            const isAdminAndNotQuestionEdit = isAdmin && permissoes[permissao] !== QUESTIONARIO_EDITAR
            const hasPermission = isAdmin ? isAdminAndNotQuestionEdit : permissoes[permissao] !== undefined

            result[permissao] = hasPermission

        });
        
        return result;

    }

    private setPermissoes(permissoes) {
        window.sessionStorage.setItem('__app-permissoes', JSON.stringify(permissoes));
    }

    private getPermissoes() : any {
        let permissoes = window.sessionStorage.getItem('__app-permissoes');
        if (permissoes)
            permissoes = JSON.parse(permissoes);

        return permissoes;
    }
}