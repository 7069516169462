// Safari in incognito has local storage, but size 0
// This system falls back to cookies in that situation
if (!window.localStorage) {
    throw Error('no local storage');
}

// Setup simple local storage wrapper
const storage = {
    set: (key : string, value : any) => localStorage.setItem(key, JSON.stringify(value)),
    get: (key: string) => {
        const item: any = localStorage.getItem(key);
        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    },
    remove: (key : string) => localStorage.removeItem(key)
};


export default storage;