import config from "../shared/constants";
import { createServiceAxios, getUser } from "./service";


function getPermissoes() : any {
    let permissoes = window.sessionStorage.getItem('__app-permissoes');
    if (permissoes)
        permissoes = JSON.parse(permissoes);

    return permissoes;
}

function setPermissoes(permissoes) {
    window.sessionStorage.setItem('__app-permissoes', JSON.stringify(permissoes));
}

async function carregarPermissoesUsuario(): Promise<{ [x: string]: string }> {

    const user = getUser();
    const userId = (user) ? user.id : 0;

    let permissoes = getPermissoes();

    if (permissoes && permissoes.user_id !== userId) {
        window.sessionStorage.removeItem('__app-permissoes');
        permissoes = undefined;
    }

    if (!permissoes) {
        try {
            
            const response = await createServiceAxios().get(config.AuthApi.obterPermissoesDoUsuario);
            const permissoes = response.data;

            if (permissoes) {
                permissoes['user_id'] = userId;
                setPermissoes(permissoes);
            }
        } catch (err) {
            //ignore
            console.error(err);
        }
    }

    permissoes = getPermissoes();

    if (!permissoes) {
        return {} as { [x: string]: string };
    }

    return permissoes as any;
}

export async function  verificarPermissoesUsuario(validarPermissoes : string[]) : Promise<{ [x:string] : boolean }> {

    const permissoes = await carregarPermissoesUsuario();
    const isAdmin = permissoes['ADMINISTRADOR'] !== undefined;
    const result: any = {};

    validarPermissoes.forEach(permissao => {
        result[permissao] = isAdmin || permissoes[permissao] !== undefined;
    });
    return result;

}