import classNames from 'classnames';
import React from 'react'
import { AppUser } from '../../models/usuario';
import { AuthService } from '../../services/AuthService';
import AppContext, { AppContextHandler } from '../../shared/store/AppContext';
import Conditional from '../../shared/ui/Conditional/Conditional';
import { StringUtil } from '../../utils';
import { AppUtil } from '../../utils/AppUtil';
import { FormUtil } from '../../utils/FormUtil';

interface LoginRequest {
    email: string;
    password: string;
    
}

interface LoginState extends LoginRequest {
    mensagemErro?: string
    carregando: boolean;
}

declare var $;

class Login extends React.Component<any, LoginState> {

    private readonly _authService = new AuthService();

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            mensagemErro: undefined,
            carregando: false,
        };
    }

    static contextType = AppContext;

    async onSubmit(event) {

        event.preventDefault();

        this.updateState({
            carregando: true,
        })

        const form = FormUtil.serializeFormObject<LoginRequest>(event.target);

        try {
            const userReponse = await this._authService.login(form.email, form.password);
            const user: AppUser = userReponse.data;

            if (user) {
                (this.context as AppContextHandler).setUser(user);

                this.props.history.push({
                    pathname: '/home'
                });

                return;

            }

            this.updateState({
                carregando: false,
            });

        } catch (err : any) {

            const update : any = {
                carregando: false,
            };

            const response = err.response;
            if (response && response.data) {
                const data = response.data;
                if (data.msg) {
                    update.mensagemErro = data.msg;
                }
            }

            this.updateState(update);
        }

    }

    async onEsqueciSenha() {
        if (StringUtil.isNullOrEmpty(this.state.email)) {
            AppUtil.showErrorMessage('E-mail inválido!');
            return;
        }

        const email = this.state.email;

        const dialogLoading = AppUtil.showLoadingDialog();

        try {
            const response = await this._authService.esqueciSenha({ email });
            AppUtil.hideLoadingDialog(dialogLoading);
            const { data } = response;
            AppUtil.showSuccessMessage(data.msg);
        } catch (err : any) {
            
            AppUtil.hideLoadingDialog(dialogLoading);

            /*
            AppUtil.hideLoadingDialog(dialogLoading);
            this.setState({
                ...this.state,
                mensagemErro: 'E-mail inválido!'
            });
            */

            const response = err.response;
            if (response && response.data) {
                const data = response.data;
                if (data.msg) {
                    const state = this.state;
                    this.setState({
                        ...state,
                        mensagemErro: data.msg
                    });
                    return;
                }
            }
        } finally {
            if ($('.bootbox').lenght > 0) {
                $($('.bootbox').get(0)).modal('hide');
            }
        }
    }


    //#region Handlers
    handleChangeInput(event, name?: string) {
        const state = { ...this.state };

        if (!name)
            name = event.target.name as string;

        const value = event.target.value;
        state[name] = value;

        this.setState(state);
    }
    //#endregion

    render() {

        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="login-logo">
                        {/* <span><b>Autoprime</b></span> */}
                        <img src={`${process.env.PUBLIC_URL}/img/logos/logo-sm-dark-aolado.png`} alt="logo" 
                            style={{ maxWidth: '210px'}}/>
                    </div>
                    {/* /.login-logo */}
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Entre com seus dados para iniciar</p>
                            <form method="post" onSubmit={this.onSubmit.bind(this)}>
                                <Conditional condition={!!this.state.mensagemErro}>
                                    <p className="text-danger text-center">{this.state.mensagemErro}</p>
                                </Conditional>
                                <div className="input-group mb-3">
                                    <input type="email" name="email" className="form-control" placeholder="Email"
                                        value={this.state.email} onChange={this.handleChangeInput.bind(this)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" name="password" className="form-control" placeholder="Password"
                                        value={this.state.password} onChange={this.handleChangeInput.bind(this)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <Conditional condition={this.state.carregando}>
                                            Carregando...
                                        </Conditional>
                                        
                                    </div>
                                    {/* /.col */}
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-dark btn-block" disabled={this.state.carregando}>Login</button>
                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                            <p className="mb-1">
                                <button type="button" className={classNames('btn', 'btn-link')}
                                    style={{color: '#000'}}
                                    onClick={(e) => this.onEsqueciSenha()}
                                    disabled={this.state.carregando}
                                >Esqueci minha senha</button>
                            </p>
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
                {/* /.login-box */}
            </div>
        );
    }

    updateState(newState: LoginState | any) {
        const state = this.state;
        const updateState = {
            ...state,
            ...newState
        };
        this.setState(updateState);
    }
}

export default Login;