/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { verificarPermissoesUsuario } from '../../services/auth.functions';
import { permissoes } from '../permissoes';

class Sidebar extends Component<any, { permissoes: { [x: string]: boolean } }> {
    get permissoes() {
        return this.state.permissoes;
    }
    constructor(props) {
        super(props);
        this.state = {
            permissoes: {} as any
        }
    }
    componentDidMount() {
        verificarPermissoesUsuario([
            //Cadastros
            permissoes.AREA_ATUACAO_LISTAR,
            permissoes.GRUPO_ECONOMICO_LISTAR,
            permissoes.EMPRESA_LISTAR,
            permissoes.GRUPO_LISTAR,
            permissoes.USUARIO_LISTAR,
            permissoes.SETOR_EMPRESA_LISTAR,
            permissoes.QUESTIONARIO_LISTAR,
            permissoes.GRAFICO_QUESTIONARIO_LISTAR,
            permissoes.CARD_LISTAR,

            //ADM
            permissoes.ADMINISTRADOR,
        ]).then(permitido => {
            this.setState({
                ...this.state,
                permissoes: permitido
            });
        })
    }

    naoPermiteAcessarMenu(p) {
        return this.permissoes[p] !== true;
    }

    naoTemPermissaoDeCadastro(): any {
        const possuiPermissao = ([
            //Cadastros
            permissoes.AREA_ATUACAO_LISTAR,
            permissoes.GRUPO_ECONOMICO_LISTAR,
            permissoes.EMPRESA_LISTAR,
            permissoes.GRUPO_LISTAR,
            permissoes.USUARIO_LISTAR,
            permissoes.SETOR_EMPRESA_LISTAR,
            permissoes.QUESTIONARIO_LISTAR,
            permissoes.GRAFICO_QUESTIONARIO_LISTAR,
            permissoes.CARD_LISTAR,
        ]).find(p => this.permissoes[p] === true);
        
        return !possuiPermissao;
    }

    render() {
        //<!-- Sidebar -->
        return (
            <div className="sidebar">
                {/* Sidebar user (optional) */}
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="../../assets/adminlte/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">Alexander Pierce</a>
                    </div>
                </div> */}
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        <li className={classNames('nav-header', { 'd-none': this.naoTemPermissaoDeCadastro() })}>Cadastros</li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.CARD_LISTAR) })}>
                            <Link to="/card-dashboard" className="nav-link">
                                <i className="fas fa-warehouse nav-icon" />
                                <p>Card Dashboard</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.AREA_ATUACAO_LISTAR) })}>
                            <Link to="/area-atuacao" className="nav-link">
                                <i className="fas fa-warehouse nav-icon" />
                                <p>Área Atuação</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.GRUPO_ECONOMICO_LISTAR) })}>
                            <Link to="/grupo-economico" className="nav-link">
                                <i className="fas fa-hand-holding-usd nav-icon" />
                                <p>Grupo Econômico</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.EMPRESA_LISTAR) })}>
                            <Link to="/empresa" className="nav-link">
                                <i className="fas fa-building nav-icon" />
                                <p>Empresa</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.SETOR_EMPRESA_LISTAR) })}>
                            <Link to="/setor-empresa" className="nav-link">
                                <i className="fas fa-industry nav-icon" />
                                <p>Setor Empresa</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.GRUPO_LISTAR) })}>
                            <Link to="/grupo-usuario" className="nav-link">
                                <i className="fas fa-users nav-icon" />
                                <p>Grupos de Usuário</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.USUARIO_LISTAR) })}>
                            <Link to="/usuario" className="nav-link">
                                <i className="fas fa-user nav-icon" />
                                <p>Usuários</p>
                            </Link>
                        </li>

                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.QUESTIONARIO_LISTAR) })}>
                            <Link to="/questionario" className="nav-link">
                                <i className="fas fa-tasks nav-icon" />
                                <p>Questionários</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.GRAFICO_QUESTIONARIO_LISTAR) })}>
                            <Link to="/grafico-questionario" className="nav-link">
                                <i className="fas fa-chart-area nav-icon" />
                                <p>Gráfico Questionário</p>
                            </Link>
                        </li>

                        <li className="nav-header">Ações</li>
                            <li className="nav-item">
                                <Link to="/responder-questionario" className="nav-link">
                                    <i className="fas fa-edit nav-icon" />
                                    <p>Responder</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/responder-questionario/geral" className="nav-link">
                                    <i className="fas fa-edit nav-icon" />
                                    <p>Responder Geral</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/solicitar-questionario-em-pausa" className="nav-link">
                                    <i className="fas fa-edit nav-icon" />
                                    <p>Solicitar Questionario</p>
                                </Link>
                            </li>
                        <li className={classNames("nav-header", { 'd-none': this.naoPermiteAcessarMenu(permissoes.ADMINISTRADOR) })}>Acompanhamentos</li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.ADMINISTRADOR) })}>
                            <Link to="/acompanhamento-questionario" className="nav-link">
                                <i className="fas fa-comment-dots nav-icon" />
                                <p>Acomp. Questionarios</p>
                            </Link>
                        </li>
                        <li className={classNames("nav-item", { 'd-none': this.naoPermiteAcessarMenu(permissoes.ADMINISTRADOR) })}>
                            <Link to="/historico-acoes" className="nav-link">
                                <i className="fas fa-history nav-icon" />
                                <p>Histórico Ações</p>
                            </Link>
                        </li>

                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
        );
    }


}

export default Sidebar;