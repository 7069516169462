import React from 'react'
import config from '../constants';

function Footer() {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> v{config.version}
            </div>
            <strong>Copyright © 2021 Autoprime.</strong> Todos os direitos reservados.
        </footer>
    );
}

export default Footer;