export class ArrayUtil {

    static isEmpty(array) {

        if(array === null || array === undefined || !Array.isArray(array))
            return true;
        

        return (array.length <= 0); 
    }

}