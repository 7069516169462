import React, { useContext } from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Content from './Content';
import MainSidebar from './MainSidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import Loading from './Loading';
import AppContext from '../store/AppContext';
import Login from '../../components/Login/Login';

const DefaultLayout = () => {
  return (
    <div className="wrapper">

      <div className="preloader flex-column justify-content-center align-items-center" style={{ height: '0px', opacity: 0.7 }}>
        <div style={{ display: 'none' }}>
          <div className="d-flex align-items-center">
            <i className="fa fa-3x fa-circle-notch fa-spin" />
            <span style={{ fontSize: '30px', paddingLeft: '10px' }}>Carregando...</span>
          </div>
        </div>

      </div>

      <Navbar />
      <MainSidebar />
      <Content />
      <Loading />
      <Footer />
    </div>
  );
}

const PaginasLogin = (props) => {
  return (
    <Switch>
      <Route exact path="/login" name="Login Page" render={props => (<Login {...props} />)} />
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  )
}

const MainLayout = (props) => {

  const userContext = useContext(AppContext);

  return (
    <Router>
      { userContext.user ? <DefaultLayout {...props} /> : <PaginasLogin {...props} />}
    </Router>
  );
}

export default MainLayout;