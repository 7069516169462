import { AxiosResponse } from "axios";
import config from "../shared/constants";
import { StringUtil } from "../utils/index";
import { createServiceAxios } from "./service";

interface NotificacoesGeraisReponse {
    total_questionarios_disponiveis: number;
}

export async function obter_notificacoes_gerais() : Promise<AxiosResponse<NotificacoesGeraisReponse>> {
    const url = `${config.BaseApi}/g/notificacoes`;
    return await createServiceAxios()
        .get<any>(url);
}