import { AuthService } from "../services/AuthService";
import { StringUtil } from "./StringUtil";
import config from "../shared/constants";

// eslint-disable-next-line
declare var $;
// eslint-disable-next-line
declare var toastr;
// eslint-disable-next-line
declare var bootbox;

export class AppUtil {

    public static confirmaExclusaoAsync(msg: string | any = undefined): Promise<boolean> {
        if (!msg) {
            msg = 'Deseja excluir esse registro?'
        }

        return new Promise<boolean>((resolve, _) => {
            const c = bootbox.confirm(msg, (response) => {
                resolve(response);
            });

            $(c).find('.modal-body').addClass('confirma-exclusao-modal')
            $(c).find('.modal-footer > .bootbox-accept').removeClass('btn-primary').addClass('btn-danger')
        })

    }

    public static showLoading() {
        const preloaderDiv = $('.preloader');
        preloaderDiv.css('height', '')
        preloaderDiv.children().show();
    }

    public static hideLoading() {
        const preloaderDiv = $('.preloader');
        preloaderDiv.css('height', '0')
        preloaderDiv.children().hide();
    }

    public static showLoadingDialog() {
        const dialog = bootbox.dialog({
            message: '<p class="text-center mb-0"><i class="fa fa-spin fa-cog"></i> Carregando...</p>',
            closeButton: false
        });

        return dialog;
    }

    public static hideLoadingDialog(dialog: any) {
        // do something in the background
        dialog.modal('hide');
    }

    public static executeWithLoading<T = any>(action: Promise<T>): Promise<T> {

        return new Promise((resolve, reject) => {
            //const dialog = AppUtil.showLoadingDialog();

            AppUtil.showLoading();

            resolve = resolve || (() => { });
            reject = reject || (() => { });

            action.then(resolve)
                .catch(reject)
                .finally(() => {
                    setTimeout(() => {
                        //AppUtil.hideLoadingDialog(dialog);
                        AppUtil.hideLoading();
                    }, 300);
                    
                });
        });
    }

    public static showSuccessMessage(title: string, message?: string) {
        if (StringUtil.isNullOrEmpty(message)) {
            message = '';
        }

        return toastr.success(message, title);
    }
    public static showErrorMessage(title: string, message?: string) {
        if (StringUtil.isNullOrEmpty(message)) {
            message = '';
        }

        return toastr.error(message, title);
    }
    public static showWarningMessage(title: string, message?: string) {
        if (StringUtil.isNullOrEmpty(message)) {
            message = '';
        }

        return toastr.warning(message, title);
    }

    public static validateErrorResponse(error) {
        if (error && error.response ) {
            const response = error.response;

            if (response.status === 401) {
                (new AuthService()).logout();
                const url = `${config.BaseAppUrl}/`;

                window.location.href = url;
                return;
            }

            if (response.status === 403) {
                const data = response.data;
                if (data && data.msg) {
                    AppUtil.showErrorMessage(data.msg);
                }

                return;
            }


        }
    }

}