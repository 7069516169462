import React from 'react';
import Context, { AppContextHandler } from './AppContext';
import useStorage from '../../utils/Storage/useStorage';
import { MySubject } from '../../utils/MySubject';

const AppProvider = ({ children }) => {
    const [user, setUser] = useStorage('user');
    const valueProvider: AppContextHandler = {
        user, setUser,
        notificacoesGerais: (new MySubject())
    }
    return (
        <Context.Provider value={valueProvider}>
            {children}
        </Context.Provider>
    )
}

export default AppProvider;