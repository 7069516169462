import React from 'react'

function Conditional(props: { children: any, condition: boolean, value?: boolean }) {
    if (!props.condition) {
        return (<></>);
    }
    
    return (<>
        { props.children }
    </>);
}

export default Conditional;