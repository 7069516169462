import React from 'react';


interface IRoute {
    path?: string,
    exact?: boolean,
    name?: string,
    component: any,

    [x: string]: any
}

//region Components
const Home = React.lazy(() => import('../../components/Home/Home'));
const Teste = React.lazy(() => import('../../components/Another/Another'));
const NotFount = React.lazy(() => import('../../components/Errors/NotFound'));

const GrupoUsuario = React.lazy(() => import('../../components/GrupoUsuario/GrupoUsuario'));
const GrupoUsuarioForm = React.lazy(() => import('../../components/GrupoUsuario/Form/GrupoUsuarioForm'));

const Empresa = React.lazy(() => import('../../components/Empresa/Empresa'));
const EmpresaForm = React.lazy(() => import('../../components/Empresa/Form/EmpresaForm'));

const Usuario = React.lazy(() => import('../../components/Usuario/Usuario'));
const UsuarioForm = React.lazy(() => import('../../components/Usuario/Form/UsuarioForm'));

const Questionario = React.lazy(() => import('../../components/Questionario/Questionario'));
const QuestionarioForm = React.lazy(() => import('../../components/Questionario/Form/QuestionarioForm'));

const ResponderQuestionario = React.lazy(() => import('../../components/ResponderQuestionario/ResponderQuestionario'));
const ResponderQuestionarioGeral = React.lazy(() => import('../../components/ResponderQuestionario/ResponderQuestionarioListaGeral'));
const SolicitarQuestionario = React.lazy(() => import('../../components/SolicitarQuestionario/SolicitarQuestionario'));

const AreaAtucao = React.lazy(() => import('../../components/AreaAtuacao/AreaAtuacao'));

const GrupoEconomico = React.lazy(() => import('../../components/GrupoEconomico/GrupoEconomico'));
const GrupoEconomicoForm = React.lazy(() => import('../../components/GrupoEconomico/Form/GrupoEconomicoForm'));

const SetorEmpresa = React.lazy(() => import('../../components/SetorEmpresa/SetorEmpresa'));

const AlterarSenhaForm = React.lazy(() => import('../../components/AlterarSenha/AlterarSenhaForm'));

const GraficoQuestionarioDashboard = React.lazy(() => import('../../components/GraficoQuestionarioDashboard/GraficoQuestionarioDashboard'));
const GraficoQuestionarioDashboardForm = React.lazy(() => import('../../components/GraficoQuestionarioDashboard/Form/GraficoQuestionarioDashboardForm'));
const GraficoQuestionarioDashboardRelatorio = React.lazy(() => import('../../components/GraficoQuestionarioDashboard/Relatorio/GraficoQuestionarioDashboardReport'));

const AcompanhamentoQuestionario = React.lazy(() => import('../../components/Acompanhamento/AcompanhamentoQuestionario/AcompanhamentoQuestionario'));

const HistoricoAcao = React.lazy(() => import('../../components/HistoricoAcoes/HistoricoAcoes'));

const CardDashboard = React.lazy(() => import('../../components/CardDashboard/CardDashboard'));

//endregion

const routes: IRoute[] = [
    { path: '/', exact: true, name: 'Home', component: Home, title: '' },
    { path: '/home', exact: true, name: 'Home', component: Home, title: 'Home' },

    { path: '/teste', exact: true, name: 'Teste', component: Teste, title: 'Teste de Página' },

    { path: '/grupo-usuario', exact: true, name: 'Setor', component: GrupoUsuario, title: 'Grupo de Usuário' },
    { path: '/grupo-usuario/novo', exact: true, name: 'Setor', component: GrupoUsuarioForm, title: 'Grupo de Usuário' },
    { path: '/grupo-usuario/:id/editar', exact: true, name: 'Setor', component: GrupoUsuarioForm, title: 'Grupo de Usuário' },

    { path: '/empresa', exact: true, name: 'Empresa', component: Empresa, title: 'Empresa' },
    { path: '/empresa/novo', exact: true, name: 'Nova Empresa', component: EmpresaForm, title: 'Empresa' },
    { path: '/empresa/:id/editar', exact: true, name: 'Empresa', component: EmpresaForm, title: 'Empresa' },

    { path: '/usuario', exact: true, name: 'Empresa', component: Usuario, title: 'Usuário' },
    { path: '/usuario/novo', exact: true, name: 'Nova Usuário', component: UsuarioForm, title: 'Usuário' },
    { path: '/usuario/:id/editar', exact: true, name: 'Usuário', component: UsuarioForm, title: 'Usuário' },

    { path: '/questionario', exact: true, name: 'Questionários', component: Questionario, title: 'Questionários' },
    { path: '/questionario/novo', exact: true, name: 'Novo Questionário', component: QuestionarioForm, title: 'Novo Questionário' },
    { path: '/questionario/:id/editar', exact: true, name: 'Editar Questionário', component: QuestionarioForm, title: 'Editar Questionário' },

    { path: '/responder-questionario', exact: true, name: 'Responder Questionário', component: ResponderQuestionario, title: 'Responder Questionário' },
    
    { path: '/responder-questionario/geral', exact: true, name: 'Responder Questionário', component: ResponderQuestionarioGeral, title: 'Responder Questionário' },
    { path: '/solicitar-questionario-em-pausa', exact: true, name: 'Solicitar Questionário', component: SolicitarQuestionario, title: 'Solicitar Questionário' },

    { path: '/card-dashboard', exact: true, name: 'Card Dashboard', component: CardDashboard, title: 'Card Home' },
    
    { path: '/area-atuacao', exact: true, name: 'Área Atuação', component: AreaAtucao, title: 'Área Atuação' },

    { path: '/setor-empresa', exact: true, name: 'Setor Empresa', component: SetorEmpresa, title: 'Setor Empresa' },

    { path: '/grupo-economico', exact: true, name: 'Grupo Economico', component: GrupoEconomico, title: 'Grupo Economico' },
    { path: '/grupo-economico/novo', exact: true, name: 'Novo Grupo Economico', component: GrupoEconomicoForm, title: 'Novo Grupo Economico' },
    { path: '/grupo-economico/:id/editar', exact: true, name: 'Editar Grupo Economico', component: GrupoEconomicoForm, title: 'Editar Grupo Economico' },

    { path: '/grafico-questionario', exact: true, name: 'Grafico Questionário', component: GraficoQuestionarioDashboard, title: 'Grafico Questionário' },
    { path: '/grafico-questionario/novo', exact: true, name: 'Novo Grafico Questionário', component: GraficoQuestionarioDashboardForm, title: 'Novo Grafico Questionário' },
    { path: '/grafico-questionario/:id/editar', exact: true, name: 'Editar Grafico Questionário', component: GraficoQuestionarioDashboardForm, title: 'Editar Grafico Questionário' },
    { path: '/grafico-questionario/:id/registros', exact: true, name: 'Registros Grafico Questionário', component: GraficoQuestionarioDashboardRelatorio, title: 'Registros Grafico Questionário' },

    { path: '/acompanhamento-questionario', exact: true, name: 'Acompanhamento de Questionário', component: AcompanhamentoQuestionario, title: 'Acompanhamento de Questionário' },

    { path: '/alterar-senha', exact: true, name: 'Alterar Senha', component: AlterarSenhaForm, title: 'Alterar Senha ' },

    { path: '/historico-acoes', exact: true, name: 'Histórico Ações', component: HistoricoAcao, title: 'Histórico Ações' },

    { path: undefined, component: NotFount, title: '' }
]

export default routes;