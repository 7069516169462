import storage from '../utils/Storage/storage';
import axios, { AxiosRequestConfig } from 'axios'
import { AppUser } from '../models/usuario';

const USER_KEY_STORAGE = 'user';

function buildHeaderAuthorization(user: AppUser): any {
    if (!user)
        return {};
    return {
        Authorization: `Bearer ${user.access_token}`
    }
}

export function getUser(): AppUser {
    return storage.get(USER_KEY_STORAGE) as AppUser;
}

export function createClearAxios(axiosArgs?: AxiosRequestConfig | any) {
    if (!axiosArgs)
        axiosArgs = {};

    return axios.create(axiosArgs);
}

export function createServiceAxios(axiosArgs?: AxiosRequestConfig | any) {
    if (!axiosArgs)
        axiosArgs = {};

    if (!axiosArgs.headers)
        axiosArgs.headers = {};

    const axiosRequest: AxiosRequestConfig = {
        ...axiosArgs,
        headers: {
            'Accept': 'application/json',
            ...axiosArgs.headers,
            ...buildHeaderAuthorization(getUser()),
        }
    };

    return createClearAxios(axiosRequest);
}