import { MySubject } from './../../utils/MySubject';
import { AppUser } from './../../models/usuario.d';
import { createContext } from 'react';

export interface AppContextHandler {
  user?: AppUser | null,
  setUser?: any,
  notificacoesGerais?: MySubject<any>
}

const defaultObjAppContext : AppContextHandler = {
  user: null, setUser: () => { }
}

const AppContext = createContext(defaultObjAppContext);

export default AppContext;