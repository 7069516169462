import storage from '../utils/Storage/storage';
import axios, { AxiosRequestConfig } from 'axios'
import { AppUser } from '../models/usuario';

export const USER_KEY_STORAGE = 'user';

export abstract class BaseService {

    protected get storage() {
        return storage;
    }

    protected getUser(): AppUser {
        return this.storage.get(USER_KEY_STORAGE) as AppUser;
    }

    public axios(axiosArgs?: AxiosRequestConfig | any) {
        if (!axiosArgs)
            axiosArgs = {};
        
        if (!axiosArgs.headers) 
            axiosArgs.headers = {};

        const axiosRequest: AxiosRequestConfig = {
            ...axiosArgs,
            headers: {
                'Accept' : 'application/json',
                ...axiosArgs.headers,
                ...this.buildHeaderAuthorization(this.getUser()),
            }
        };

        return this.clearAxios(axiosRequest);
    }

    public clearAxios(axiosArgs?: AxiosRequestConfig | any) {
        if (!axiosArgs)
            axiosArgs = {};

        return axios.create(axiosArgs);
    }

    private buildHeaderAuthorization(user: AppUser): any {
        if (!user)
            return {};
        return {
            Authorization: `Bearer ${user.access_token}`
        }
    }
}