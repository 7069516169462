const version = process.env.REACT_APP_VERSION;
const BaseApiUrl = process.env.REACT_APP_API_URL;
const BaseAppUrl = process.env.PUBLIC_URL;

const apiBaseUrl = `${BaseApiUrl}/api`;

const AuthApi = {
    Login: `${apiBaseUrl}/auth/login`,
    Me: `${apiBaseUrl}/auth/me`,
    IsTokenAlive: `${apiBaseUrl}/auth/is-token-alive`,
    AlterarSenha: `${apiBaseUrl}/auth/alterar-senha`,
    EsqueciSenha: `${apiBaseUrl}/auth/esqueci-senha`,
    obterPermissoesDoUsuario: `${apiBaseUrl}/auth/permissoes-usuario`
}

const SistemaApi = {
    ObterGrupos: `${apiBaseUrl}/sistema/obter-grupos`,
    ObterPermissoes: `${apiBaseUrl}/sistema/obter-permissoes`,
    PesquisaGenerica: `${apiBaseUrl}/sistema/pesquisa-generica`,
};

const SetorApi = {
    ObterTodos: `${apiBaseUrl}/setor`,
    ObterPorId: `${apiBaseUrl}/setor/{0}`,
    Api: `${apiBaseUrl}/setor`,
}

const EmpresaApi = {
    ObterTodos: `${apiBaseUrl}/empresa`,
    ObterPorId: `${apiBaseUrl}/empresa/{0}`,
    Api: `${apiBaseUrl}/empresa`,
}

const UsuarioApi = {
    ObterTodos: `${apiBaseUrl}/usuario`,
    ObterPorId: `${apiBaseUrl}/usuario/{0}`,
    Api: `${apiBaseUrl}/usuario`,
}

const GrupoUsuarioApi = {
    ObterTodos: `${apiBaseUrl}/grupo-usuario`,
    ObterPorId: `${apiBaseUrl}/grupo-usuario/{0}`,
    Api: `${apiBaseUrl}/grupo-usuario`,
}

const QuestionarioApi = {
    ObterTodos: `${apiBaseUrl}/questionario`,
    ObterPorId: `${apiBaseUrl}/questionario/{0}`,
    Atualizar: `${apiBaseUrl}/questionario/{0}`,
    DefinirDataFinal: `${apiBaseUrl}/questionario`,
    ObterQuestionariosAtivosParaUsuario: `${apiBaseUrl}/questionario/obter-ativos`,
    Api: `${apiBaseUrl}/questionario`,
    definirMetaPergunta: `${apiBaseUrl}/questionario/definir-meta-pergunta`,
    obterMetaPergunta: `${apiBaseUrl}/questionario/obter-meta-pergunta/{0}`,
    Responder: `${apiBaseUrl}/questionario/responder`,
    ObterEmPausa: `${apiBaseUrl}/questionario/obter-pausa`,
    IncluirFila: `${apiBaseUrl}/questionario/incluir-fila/{0}`,
}

const FormularioApi = QuestionarioApi;

const CardDashboardApi = {
    ObterTodos: `${apiBaseUrl}/card`,
    ObterPorId: `${apiBaseUrl}/card/{0}`,
    Api: `${apiBaseUrl}/card`,
}

const AreaAtuacaoApi = {
    ObterTodos: `${apiBaseUrl}/area-atuacao`,
    ObterPorId: `${apiBaseUrl}/area-atuacao/{0}`,
    Api: `${apiBaseUrl}/area-atuacao`,
}

const GrupoEconomicoApi = {
    ObterTodos: `${apiBaseUrl}/grupo-economico`,
    ObterPorId: `${apiBaseUrl}/grupo-economico/{0}`,
    Api: `${apiBaseUrl}/grupo-economico`,
}

const SetorEmpresaApi = {
    ObterTodos: `${apiBaseUrl}/setor-empresa`,
    ObterPorId: `${apiBaseUrl}/setor-empresa/{0}`,
    Api: `${apiBaseUrl}/setor-empresa`,
}

const GraficoQuestionarioDashboardApi = {
    ObterTodos: `${apiBaseUrl}/grafico-questionario-dashboard`,
    ObterPorId: `${apiBaseUrl}/grafico-questionario-dashboard/{0}`,
    Api: `${apiBaseUrl}/grafico-questionario-dashboard`,
}

const config = {
    version,
    BaseAppUrl,
    BaseUrl: BaseAppUrl +'/',
    BaseApi: apiBaseUrl,
    AuthApi,
    SistemaApi,
    SetorApi,
    EmpresaApi,
    UsuarioApi,
    GrupoUsuarioApi,
    FormularioApi,
    AreaAtuacaoApi,
    GrupoEconomicoApi,
    SetorEmpresaApi,
    QuestionarioApi,
    GraficoQuestionarioDashboardApi,
    CardDashboardApi
}

export default config;
