import randomstring from 'randomstring';

export class StringUtil {

    public static format(str: string, ...args: any[]) {
        //const args = Array.prototype.slice.call(arguments);
        //const str = args.shift();
        return str.replace(/{(\d+)}/g, (match, number) => typeof args[number] != 'undefined' ? args[number] : match);
    }

    public static isNullOrEmpty(str?: string | undefined | null, force = false) {

        if (str === undefined || str === null || (typeof str === 'string' && str.length <= 0))
            return true;

        if (typeof str !== 'string') {
            if (!force)
                return true;

            str = (str as any).toString();

            if (str === undefined || str === null || str.length <= 0)
                return true;

            return false;
        }

        return str.trim().length <= 0;
    }

    static onlyNumbers(value: string | undefined | null): string | any {

        if (StringUtil.isNullOrEmpty(value))
            return value;

            /** @ts-ignore */
        return value.replace(/\D/gi, '');
    }

    static randomString(length) {
        return randomstring.generate({ length });
    }
}