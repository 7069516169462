import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import routes from '../routes/routes';
import Page from './Page';


const HandlerRoute = routes.map((route, idx) => {
    return route.component && (
        <Route key={idx} path={route.path} exact={route.exact} name={route.name}
            render={props => (<Page {...route}> <route.component {...props} /> </Page>)}
        />
)
});

class Content extends Component {
    render() {
        //<!-- Content Wrapper. Contains page content -->
        return (
            <div className="content-wrapper px-lg-4">
                <Router>
                    <Switch>
                        {HandlerRoute}
                    </Switch>
                </Router>
            </div>
        );
    }

}

export default Content;