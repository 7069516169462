import React from 'react';
import './App.scss';
import MainLayout from './shared/layout/MainLayout';
import AppProvider from './shared/store/AppProvider';

import LoadingGeneral from './shared/layout/LoadingGeneral';

function App(props) {

  return (
    <React.Suspense fallback={<LoadingGeneral />}>
      <AppProvider>
        <MainLayout {...props} />
      </AppProvider>
    </React.Suspense>

  );
}

export default App;
