import { AppUtil } from "./AppUtil";

declare var $;

export class FormUtil {

    static serializeFormObject<T = any>(querySelector: string | any): T {
        const ret = {};
        $(querySelector).serializeArray().forEach((v: any, k: number) => {
            let name = v.name;
            if (name.match(/\[\]$/)) {
                name = name.substr(0, name.length - 2);
                if (typeof ret[name] === "undefined") {
                    ret[name] = [];
                }
                return ret[name].push(v.value);
            } else {
                return ret[name] = v.value;
            }

        });
        return ret as T;

    };

    static setValidate(formQuery, rules: any) {

        $(formQuery).validate({
            rules,

            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        })

    }

    static maskInputs() {

        $('[data-mask]').each(function (i, obj) {
            obj = $(obj);
            let mask = obj.attr('data-mask');
            obj.mask(mask);
        });

        $('input.percentual').mask('990,00', { reverse: true, placeholder: '0,00' });
        $('input.percentual-noplaceholder').mask('990,00', { reverse: true });

        $('input.perc').unmask().mask('990,0000', { reverse: true, placeholder: '000,0000' });

        $('input.perc').attr('pattern', '^\\d{1,3},\\d{4}');
        $('input.perc').attr('pattern-message', '000,0000');

        $('input.money').mask('#.##0,00', { reverse: true, placeholder: '0,00' });

        $('input.money').attr('pattern', '\\d{0,3}.?\\d{0,3}.?\\d{0,3}.?\\d{0,3},\\d{2}');
        $('input.money').attr('pattern-message', '0,00');


        $('input.dinheiro').mask('#.##0,00', { reverse: true, placeholder: '0,00' });
        $('input.peso').mask('#.##0,000', { reverse: true, placeholder: '0,000' });

        $('input.money-4').mask('#.##0,0000', { reverse: true });
        $('input.dinheiro-4').mask('#.##0,0000', { reverse: true });

        $('input.numbers').mask('#', { reverse: true });


        $('.money').blur(function () {
            let pattern = /^\d{1,2}$/;
            //@ts-ignore
            let str = $(this);
            let val = str.val();
            let regex = new RegExp(pattern);
            if (regex.test(val)) {
                val += ',00';
                str.val(val);
            }
        });

        $('.perc').blur(function () {
            let pattern = /^\d{1,4}$/;
            //@ts-ignore
            let str = $(this);
            let val = str.val();
            let regex = new RegExp(pattern);
            if (regex.test(val)) {
                val += ',0000';
                str.val(val);
            }
        });


        $('input.numeros').mask('#', { reverse: true });

        $('input.fone').mask('(00) 0000-00009');

        $('input.cnpj').unmask().mask('00.000.000/0000-00', { placeholder: '00.000.000/0000-00' });
        
        $('input.cnpj2').unmask().mask('00.000.000/0000-00');

        $('input.cpf').mask('000.000.000-00', { placeholder: '000.000.000-00' });

        $('input.cep').mask('00000-000', { placeholder: '00000-000' });

        $('input.data').mask('00/00/0000', { placeholder: 'dd/mm/yyyy' });

        $('input.hora').mask('00:00:00', { placeholder: '00:00:00' });

    }

    static prepareObject<T = any>(obj) : T {
        if (obj === undefined || obj === null)
            return obj;
            
        Object.keys(obj).forEach(key => {
            if (obj[key] === null || obj[key] === undefined) {
                obj[key] = '';
            }
        });

        return obj;
    }

    static adaptServiceErrors(error) {
        let message = 'Ocorreu um erro na requisição';

        let erros : string[] = [];

        let isWarning = false;
        if (error && error.response ) {
            const response = error.response;

            if (response.status === 422 || response.status === 400) {
                const data = response.data;

                if (data.msg && data.result) {
                    isWarning = true;
                    message = data.msg;

                    if (response.status === 422) {
                        const keysResult = Object.keys(data.result);
                        for (const key of keysResult) {
                            const obj = data.result[key];
                            if (typeof obj === 'string') {
                                erros.push(obj as any);
                            } else if (Array.isArray(obj)) {
                                erros = erros.concat(obj as any)
                            }
                        }
                    } else {
                        erros.push(message);
                    }
                    
                }
            }
            
            if (response.status === 500 || response.status === 403) {
                const data = response.data;
                if (data && data.msg) {
                    message = data.msg;
                }
            }
        }

        if (isWarning) {
            AppUtil.showWarningMessage(message);
        } else {
            AppUtil.showErrorMessage(message);
        }

        return erros;
    }
}